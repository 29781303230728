import React, { useState } from 'react'
import { Spin } from 'antd'
import ModalFrotabank from '../../../../Shared/Components/Modal'
import { LoadingOutlined } from '@ant-design/icons'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
import { AiOutlineClose } from 'react-icons/ai'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import api from '../../../../api/api_frota_bank'
import './styles.css'
import OpenNotification from '../../../../Shared/utils/open_notification'
import Spacer from '../../../../Shared/Components/Spacer'
import { PhoneInput } from '@/components/Masks'
import { CNPJCPFInput } from '@/components/Masks'

function RightSide() {
  const [isLoading, setIsLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const loadingComponent = <LoadingOutlined style={{ fontSize: 25 }} spin />

  const validCnpjCpf = (event) => {
    var input = event
    if (typeof input === 'string') {
      var cpf = input
      if (cpf.length === 11) {
        cpf = event.toString()
        cpf = cpf.replace(/[\s.-]*/gim, '')
        if (
          !cpf ||
          cpf.length !== 11 ||
          cpf === '00000000000' ||
          cpf === '11111111111' ||
          cpf === '22222222222' ||
          cpf === '33333333333' ||
          cpf === '44444444444' ||
          cpf === '55555555555' ||
          cpf === '66666666666' ||
          cpf === '77777777777' ||
          cpf === '88888888888' ||
          cpf === '99999999999'
        ) {
          return false
        }
        var soma = 0
        var resto
        for (var i = 1; i <= 9; i++)
          soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
        resto = (soma * 10) % 11
        if (resto === 10 || resto === 11) resto = 0
        if (resto !== parseInt(cpf.substring(9, 10))) return false
        soma = 0
        for (var j = 1; j <= 10; j++)
          soma = soma + parseInt(cpf.substring(j - 1, j)) * (12 - j)
        resto = (soma * 10) % 11
        if (resto === 10 || resto === 11) resto = 0
        if (resto !== parseInt(cpf.substring(10, 11))) return false

        return true
      } else if (cpf.length === 14) {
        console.log('teste')
        var cnpj = input

        cnpj = cnpj.replace(/[^\d]+/g, '')

        if (cnpj === '') return false

        if (cnpj.length !== 14) return false

        // Elimina CNPJs invalidos conhecidos
        if (
          cnpj === '00000000000000' ||
          cnpj === '11111111111111' ||
          cnpj === '22222222222222' ||
          cnpj === '33333333333333' ||
          cnpj === '44444444444444' ||
          cnpj === '55555555555555' ||
          cnpj === '66666666666666' ||
          cnpj === '77777777777777' ||
          cnpj === '88888888888888' ||
          cnpj === '99999999999999'
        )
          return false

        // Valida DVs
        var tamanho = cnpj.length - 2
        var numeros = cnpj.substring(0, tamanho)
        var digitos = cnpj.substring(tamanho)
        var soma2 = 0
        var pos = tamanho - 7
        for (i = tamanho; i >= 1; i--) {
          soma2 += numeros.charAt(tamanho - i) * pos--
          if (pos < 2) pos = 9
        }
        var resultado = soma2 % 11 < 2 ? 0 : 11 - (soma2 % 11)
        if (resultado != digitos.charAt(0)) return false

        tamanho = tamanho + 1
        numeros = cnpj.substring(0, tamanho)
        soma2 = 0
        pos = tamanho - 7
        for (i = tamanho; i >= 1; i--) {
          soma2 += numeros.charAt(tamanho - i) * pos--
          if (pos < 2) pos = 9
        }
        resultado = soma2 % 11 < 2 ? 0 : 11 - (soma2 % 11)
        if (resultado != digitos.charAt(1)) return false

        return true
      }
    } else {
      console.log('teste')
    }
  }

  Yup.addMethod(Yup.string, 'validCnpjCpf', validCnpjCpf)

  const schema = Yup.object().shape({
    nomeCompleto: Yup.string()
      .required('Nome completo é obrigatório!')
      .min('3', 'Nome muito curto'),
    celular: Yup.string().required('Celular é obrigatório'),
    email: Yup.string()
      .email('E-mail inválido')
      .required('E-mail é obrigatório'),
    cpf: Yup.string()
      .test('validarCpf', 'CPF/CNPJ inválido', (value) => validCnpjCpf(value))
      .required('CPF é obrigatório'),
    codIndicacao: Yup.string().nullable(),
    aceitouTermos: Yup.bool().oneOf(
      [true],
      'Aceitar as políticas de privacidade é obrigatório',
    ),
    categoria: Yup.string().required('Selecione uma categoria CNH'),
  })

  const onSubmit = async (formData) => {
    if (formData.categoria === 'Selecione' || formData.categoria === '') {
      OpenNotification('Verifique os campos!', 'Selecione uma categoria CNH')
      return
    }

    try {
      setIsLoading(true)

      const data = {
        cod_adm: 1,
        val_celular: formData.celular,
        des_nome: formData.nomeCompleto,
        val_cpf: formData.cpf,
        email: formData.email,
        val_indicado: formData.codIndicacao,
        categoria_cnh: formData.categoria,
      }

      const response = await api.post('/criar/indicacao', data)

      OpenNotification(
        response.status === 200 ? 'Cadastrado com sucesso!' : 'Cadastro',
        response.data.message,
      )
      if (response.status === 200) {
        setTimeout(() => {
          window.location.href = 'https://www.frotabank.com.br/app/'
        }, 1200)
      }
    } catch (e) {
      OpenNotification(
        'Cadastro',
        'Aconteceu algum erro ao tentar realizar o cadastro, Tente novamente!',
      )
    } finally {
      setIsLoading(false)
    }
  }

  function toggleModal() {
    setIsModalVisible(!isModalVisible)
  }

  return (
    <>
      <ModalFrotabank
        title="Política de privacidade"
        key="politica de privacidade"
        handleOk={toggleModal}
        isModalVisible={isModalVisible}
        handleCancel={toggleModal}
      />

      <div className="right-container">
        <div className="right-content">
          <div className="text-right-container">
            <div className="x-button">
              <Link to="/">
                <AiOutlineClose className="x-icon" />
              </Link>
            </div>
            <p>
              Agora complete abaixo e depois baixe o aplicativo para concluir a
              abertura da sua conta
            </p>
          </div>
          <Formik
            validationSchema={schema}
            initialValues={{
              nomeCompleto: '',
              celular: localStorage.getItem('whatsapp') ?? '',
              email: '',
              cpf: '',
              codIndicacao: localStorage.getItem('utm_content'),
              aceitouTermos: false,
              categoria: 'Selecione',
            }}
            className="formik-container"
            onSubmit={onSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="form-control">
                  <Field
                    style={{
                      borderColor: '#6666',
                      borderWidth: 3,
                      marginTop: 21,
                    }}
                    className="field"
                    placeholder="Nome completo"
                    id="nomeCompleto"
                    name="nomeCompleto"
                    type="text"
                  />
                  <ErrorMessage
                    name="nomeCompleto"
                    component="div"
                    id="error"
                  />

                  <div className="divided-fields">
                    <div className="left-field-container">
                      <Field
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          borderWidth: 3,
                          borderColor: '#6666',
                          marginBottom: '13px',
                          fontSize: '15px',
                          paddingBottom: '2px',
                        }}
                        className="field"
                        placeholder="Celular"
                        id="celular"
                        name="celular"
                        type="tel"
                        as={PhoneInput}
                      />
                      <div>
                        <ErrorMessage
                          name="celular"
                          component="div"
                          id="error"
                        />
                      </div>
                    </div>

                    <div className="right-field-container">
                      <Field
                        style={{
                          borderTop: 0,
                          borderLeft: 0,
                          borderRight: 0,
                          borderWidth: 3,
                          borderColor: '#6666',
                          marginBottom: '13px',
                          fontSize: '15px',
                          paddingBottom: '2px',
                        }}
                        //className="field"
                        placeholder="CPF ou CNPJ"
                        id="cpf"
                        name="cpf"
                        type="text"
                        as={CNPJCPFInput}
                      />
                      <ErrorMessage name="cpf" component="div" id="error" />
                    </div>
                  </div>

                  <Field
                    style={{
                      borderColor: '#6666',
                      borderWidth: 3,
                      marginTop: 21,
                    }}
                    className="field"
                    placeholder="E-mail"
                    id="email"
                    name="email"
                    type="email"
                  />

                  <ErrorMessage name="email" component="div" id="error" />

                  <Field
                    className="field"
                    placeholder="Código de Indicação"
                    id="codIndicacao"
                    name="codIndicacao"
                    type="text"
                  />
                  <ErrorMessage
                    name="codIndicacao"
                    component="div"
                    id="error"
                  />

                  <div style={{ fontSize: 10, color: '#03656E' }}>
                    Se algum amigo te indicou, coloque o código dele
                  </div>
                </div>

                <Spacer />
                <div className="select-container">
                  <div>
                    <label className="label-text-cnh" htmlFor="categorias">
                      Categoria CNH*:
                    </label>
                  </div>
                  <select
                    className="select-form"
                    placeholder="Selecione"
                    style={{ width: '100%', height: '33px' }}
                    onChange={(value) => {
                      setFieldValue('categoria', value.target.value)
                    }}
                    name="categoria"
                    defaultValue="Selecione"
                    id="categorias-select"
                  >
                    <option value="Selecione">Selecione</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="A/B">A/B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                  </select>
                </div>
                <ErrorMessage name="categoria" component="div" id="error" />

                <div className="condicoes-container">
                  <Field
                    type="checkbox"
                    id="aceitouTermos"
                    name="aceitouTermos"
                  />
                  <label>
                    Eu li e estou ciente das condições de tratamento dos meus
                    dados pessoais e do meu consentimento, quando aplicável,
                    conforme descrito nesta{' '}
                    <button
                      type="button"
                      onClick={toggleModal}
                      className="btn-politica-privacidade"
                    >
                      Política de Privacidade
                    </button>
                    .
                  </label>
                </div>
                <ErrorMessage
                  style={{ marginBottom: '20px', paddingLeft: '10px' }}
                  name="aceitouTermos"
                  component="div"
                  id="error"
                />

                <Spacer margin="10%" />

                {!isLoading ? (
                  <div className="button-container">
                    <button className="button-primary" type="submit">
                      Continuar e baixar o App{' '}
                      <HiOutlineArrowNarrowRight className="arrow-avancar" />
                    </button>
                  </div>
                ) : (
                  <div className="button-container">
                    <button className="button-primary loading">
                      <Spin
                        indicator={loadingComponent}
                        style={{ alignContent: 'center' }}
                      />
                    </button>
                  </div>
                )}
                <Spacer />
              </Form>
            )}
          </Formik>
          <Spacer />
        </div>
      </div>
    </>
  )
}

export default RightSide
